<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'
import router from '@/router'

export default {
  page: {
    title: "Payment Confirmation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
  },
  props:{
      invoice_id: String
  },
  setup(props){
    const { $post } = useHttp()
    const { $get } = useHttp()
    const form = ref({
      payInvId: '',
      payAmount: 0,
      payOrderId: ''
    })

    const formdisplay = ref({
      invoiceNumber: '',
      orderNumber: '',
      smuNumber: '',
      customerName:'',
      airlinesName:'',
      flight:'',
      grandtotal:''
    })

    const getData = async () => {
      const {data} = await $get({ url: 'master/invoice/' + props.invoice_id})
      form.value.payInvId = data.inv_id
      form.value.payOrderId = data.inv_orderid
      formdisplay.value.invoiceNumber = data.inv_number
      formdisplay.value.orderNumber = data.order.ord_no
      if(data.order.ord_manifestno !== null){
        formdisplay.value.orderNumber += '/'+ data.order.ord_manifestno
      }
      formdisplay.value.customerName = data.customer.name
      formdisplay.value.airlinesName = data.invSchedule.vendor.name
      formdisplay.value.flight = `${data.invSchedule.sch_flight_no} (${data.invSchedule.originport.prt_initial} - ${data.invSchedule.destinationport.prt_initial})`
      formdisplay.value.inv_gtotalcharge = formatNumber(data.inv_gtotalcharge)
      form.value.payAmount = data.inv_gtotalcharge
    }

    const validationForm = async (event) => {
      event.target.disabled = true
      $post({
        url: 'payment',
        data: form.value
      })
      router.push({name: 'apps-invoice-list'})
      event.target.disabled = false
    }

    onMounted(() => {
      getData()
    })
    return {
      formatNumber,
      formdisplay,
      form,
      validationForm
    }
  },
  data() {
    return {
      title: "Payment Confirmation",
      items: [],
      value: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

<div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Invoice No."
                  >
                    <b-form-input v-model="formdisplay.invoiceNumber" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order/SMU No."
                  >
                    <b-form-input v-model="formdisplay.orderNumber" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Customer"
                  >
                    <b-form-input v-model="formdisplay.customerName" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Airlines"
                  >
                    <b-form-input v-model="formdisplay.airlinesName" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Flight"
                  >
                    <b-form-input v-model="formdisplay.flight" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Grand Total Amount"
                  >
                    <b-form-input v-model="formdisplay.inv_gtotalcharge" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Paid Amount"
                  >
                    <b-form-input v-model="form.payAmount"></b-form-input>
                  </b-form-group>
                  <!--<b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Proof of Payment"
                    label-for="mvs_datefrom"
                  >
                    <div class="custom-file">
                      <input id="customFile" type="file" class="custom-file-input" />
                      <label class="custom-file-label" for="customFile">Choose file</label>
                    </div>
                  </b-form-group>-->
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'apps-invoice-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
